import {PodcastDirectoryEnum} from '@pinecast/gql/__generated_sl__/globalTypes';

import * as DirectoryIcons from './icons/directories';

export type Podcastish = {
  spotifyId: string | null;
  directoryListings: ReadonlyArray<{
    service: PodcastDirectoryEnum;
    serviceId: string;
  }>;
};

function hasDirectory(service: PodcastDirectoryEnum) {
  return (podcast: Podcastish) =>
    podcast.directoryListings.some(x => x.service === service);
}
function alwaysApplicable() {
  return true;
}
function neverApplicable() {
  return false;
}

export type Directory = {
  icon: JSX.Element;
  name: string;
  recommendedFor: Array<'iOS' | 'android' | 'macOS' | 'web' | 'windows'>;
  applicable: (podcast: Podcastish) => boolean;
};

export const DIRECTORIES: Record<string, Directory> = {
  [PodcastDirectoryEnum.APPLE]: {
    icon: <DirectoryIcons.ApplePodcasts />,
    name: 'Apple Podcasts',
    recommendedFor: ['iOS', 'macOS', 'web'],
    applicable: hasDirectory(PodcastDirectoryEnum.APPLE),
  },
  SPOTIFY: {
    icon: <DirectoryIcons.Spotify />,
    name: 'Spotify',
    recommendedFor: ['iOS', 'android', 'macOS', 'windows', 'web'],
    applicable: (podcast: Podcastish) => Boolean(podcast.spotifyId),
  },
  CASTRO: {
    icon: <DirectoryIcons.Castro />,
    name: 'Castro',
    recommendedFor: ['iOS'],
    applicable: hasDirectory(PodcastDirectoryEnum.APPLE),
  },
  CASTBOX: {
    icon: <DirectoryIcons.Castbox />,
    name: 'Castbox',
    recommendedFor: ['iOS'],
    applicable: hasDirectory(PodcastDirectoryEnum.APPLE),
  },
  DOWNCAST: {
    icon: <DirectoryIcons.Downcast />,
    name: 'Downcast',
    recommendedFor: ['iOS'],
    applicable: () =>
      Boolean(
        /os x/i.exec(navigator.userAgent) || /macos/i.exec(navigator.userAgent),
      ),
  },
  OVERCAST: {
    icon: <DirectoryIcons.Overcast />,
    name: 'Overcast',
    recommendedFor: ['iOS'],
    applicable: hasDirectory(PodcastDirectoryEnum.APPLE),
  },
  PLAYERFM: {
    icon: <DirectoryIcons.PlayerFM />,
    name: 'Player.fm',
    recommendedFor: ['android'],
    applicable: alwaysApplicable,
  },
  POCKETCASTS: {
    icon: <DirectoryIcons.PocketCasts />,
    name: 'Pocket Casts',
    recommendedFor: ['iOS', 'android', 'web'],
    applicable: hasDirectory(PodcastDirectoryEnum.POCKETCASTS),
  },
  PODCASTREPUBLIC: {
    icon: <DirectoryIcons.PodcastRepublic />,
    name: 'Podcast Republic',
    recommendedFor: [],
    applicable: hasDirectory(PodcastDirectoryEnum.APPLE),
  },
  // RADIOPUBLIC: {
  //   icon: <DirectoryIcons.RadioPublic />,
  //   name: 'RadioPublic',
  //   recommendedFor: ['web'],
  //   applicable: alwaysApplicable,
  // },
  SONNET: {
    icon: <DirectoryIcons.Sonnet />,
    name: 'Sonnet.fm',
    recommendedFor: ['android'],
    applicable: hasDirectory(PodcastDirectoryEnum.APPLE),
  },
  TUNEIN: {
    icon: <DirectoryIcons.TuneIn />,
    name: 'TuneIn',
    recommendedFor: [],
    applicable: neverApplicable,
  },
  [PodcastDirectoryEnum.AMAZON]: {
    icon: <DirectoryIcons.AmazonMusic />,
    name: 'Amazon Music',
    recommendedFor: [],
    applicable: hasDirectory(PodcastDirectoryEnum.AMAZON),
  },
  [PodcastDirectoryEnum.IHEARTRADIO]: {
    icon: <DirectoryIcons.iHeartRadio />,
    name: 'iHeartRadio',
    recommendedFor: [],
    applicable: hasDirectory(PodcastDirectoryEnum.IHEARTRADIO),
  },
};

export function getFilteredDirectories(podcast: Podcastish) {
  return Object.entries(DIRECTORIES).filter(([, v]) => v.applicable(podcast));
}

export function getOSList() {
  const os = [
    /ios/i.exec(navigator.userAgent)
      ? 'iOS'
      : /iphone/i.exec(navigator.userAgent)
        ? 'iOS'
        : /ipod/i.exec(navigator.userAgent)
          ? 'iOS'
          : /ipad/i.exec(navigator.userAgent)
            ? 'iOS'
            : /android/i.exec(navigator.userAgent)
              ? 'android'
              : /macos/i.exec(navigator.userAgent)
                ? 'macOS'
                : /os x/i.exec(navigator.userAgent)
                  ? 'macOS'
                  : /windows/i.exec(navigator.userAgent)
                    ? 'windows'
                    : 'web',
  ];
  if (os[0] === 'macOS' || os[0] === 'windows') {
    os.push('web');
  }
  return os;
}
